import React, { Fragment, useState } from "react";
import { graphql } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

export default function Privacy() {

  return (
    <Layout>
      <div className="relative">
        <div className="absolute top-48 right-0 bottom-0 left-0 -z-10">
          <StaticImage
            src="../images/purple-center.webp"
            alt="logo"
            className="centerbg"
          />
        </div>
        <div className="max-w-[1366px] mx-auto px-5 lg:px-0">
          <div className="pt-[105px] mx-auto max-w-[850px]">
            <p className="font-medium leading-[54px] text-3xl lg:text-[36px] text-white text-center">
                <span>Privacy Policy</span>
            </p>
            <div className="text-white terms-text">
            
              <p class="p2"><span class="s2">Updated:&nbsp;&nbsp; February 14, 2023</span></p>
              <p class="p4">
                  <span class="s2">
                      Welcome to Thunderhead Interests, LLC [TI] &ndash;This Privacy Policy relates to our information collection and use practices in connection with our technology platform, software, and services (the &ldquo;Platform&rdquo;) and our
                      publicly available website (the &ldquo;Website&rdquo;). Capitalized&nbsp;terms not defined in this Privacy Policy shall have the meaning set forth in our&nbsp;Terms and Conditions.
                  </span>
              </p>


              <ol class="ol1">
                <li class="li5">
                    <span class="s3"><strong>The Information We Collect and/or Receive</strong></span>
                </li>
               
                <p class="p2"><span class="s2">&nbsp; &nbsp; &nbsp;In the course of operating the Platform and Website, TI will collect (and/or receive) the following types of information.&nbsp;</span></p>
                <p class="p2">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;<strong>Personal Information</strong>. When you make a purchase and when you and your Authorized Users register to use the Platform, you will be required to provide personal information about yourself, such as
                        your name, e-mail address, telephone number and similar information (collectively, &ldquo;Personal Information&rdquo;) in order for us to perform our contract with you. When you sign up for the mailing list or other promotion on our
                        Website, we request your company name, email address, first and last name, and similar information.&nbsp;
                    </span>
                </p>
                <p class="p2">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;<strong>Billing Information</strong>.&nbsp;&nbsp;In order for us to process your payments and perform our contract with you, you will be required to provide certain billing information, such as your credit card
                        number, expiration date, bank account information, billing address, activation code, and similar information (collectively, &ldquo;Billing Information&rdquo;).&nbsp; You authorize our third-party payment vendors to collect, process,
                        and store your Billing Information in accordance with their respective privacy policies.&nbsp; We currently use Quickbook Payments as our third-party payment vendor, but we reserve the right to change our payment vendor at any time,
                        or to use additional payment vendors, at our discretion, and will update this Privacy Policy from time to time accordingly.&nbsp; Stripe&rsquo;s privacy policy may be
                        <a
                            href="https://quickbooks.intuit.com/learn-support/en-us/help-article/banking/privacy-security-quickbooks/L7q2gUqfM_US_en_US#:~:text=The%2520connection%2520and%2520info%2520from%2520your%2520bank%2520are,-%2520from%2520your%2520bank%2520to%2520your%2520QuickBooks%2520account."
                        >
                            <span class="s4">found here:</span>
                        </a>
                        <span class="Apple-converted-space">&nbsp; </span>TI does not store or process your Billing Information.
                    </span>
                </p>
                <p class="p2">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;<strong>Geolocation Information</strong>.&nbsp;&nbsp;Certain features and functionalities of the Platform may be based on your location, device, or other setting.&nbsp; In order to provide specific features and
                        functionalities we may, with your consent, collect information from your device or wireless carrier and/or certain third-party service providers, such as geolocation and language settings (collectively the &ldquo;Geolocation
                        Information&rdquo;).&nbsp; You may decline to allow us to collect such Geolocation Information, in which case we may not be able to provide certain features or functionalities to you.
                    </span>
                </p>
                <p class="p2">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;Other Information.&nbsp;&nbsp;In addition to the Personal Information, the Billing Information, and the Geolocation Information, we may collect or receive additional information (collectively, the &ldquo;Other
                        Information&rdquo;).&nbsp; Such Other Information may include:
                    </span>
                </p>
                <ol style={{listStyleType: "lower-alpha"}}>
                    
                    <li class="p7">
                        <span class="s2">
                            Activity.&nbsp; In order to provide you certain features and functionalities of the Platform or Website,&nbsp; maintain our business relationship and as a matter of our legitimate interests to improve our Platform, Website, and
                            services, we automatically collect certain information when you use the Platform or Website, and integrate it with your other devices and services.&nbsp; Such information may include, without limitation:
                        </span>
                        
                        <ul class="ul1">
                            <li class="li8">
                                <span class="s3">IP addresses, browser type and language, referring and exit pages and URLs, date and time, amount of time spent on the Platform or Website, what sections of the Platform or Website you visit, etc.;<br />
                                </span>
                            </li>
                            <li class="li9">
                                <span class="s3">
                                    information about a mobile device, including universally unique ID (&ldquo;UUID&rdquo;), platform type and version (e.g., &nbsp;iOS or Android), carrier and country location, hardware and processor information, and
                                    network type; and activity and usage information occurring via the Platform or Website.
                                </span>
                            </li>
                        </ul>
                    </li>
              
                    <li class="li9">
                        Cookies.&nbsp; We collect information using &ldquo;cookie&rdquo; technology.&nbsp; Cookies are small packets of data that a website stores on your computer&rsquo;s or mobile device&rsquo;s hard drive so that your device will
                        &ldquo;remember&rdquo; information about your visit to our Platform or Website, or use of the services.&nbsp; We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your
                        computer until you delete them) to help us collect Other Information and to enhance your experience using the Platform or Website.&nbsp; If you do not want us to place a cookie on your hard drive, you may be able to turn that
                        feature off on your computer or mobile device. Please consult your Internet browser&rsquo;s documentation for information on how to do this and how to delete persistent cookies.&nbsp; However, if you decide not to accept cookies
                        from us, the Platform or Website may not function properly.&nbsp;
                    </li>
              
                    <li class="p2">
                        <span class="s2">
                            Third-Party Analytics and Customer Support Providers. We use third-party analytics and customer support services (such as Google Analytics) to evaluate your use of the Platform or Website, compile reports on activity and events,
                            collect demographic data, analyze performance metrics, and collect and evaluate other information relating to the Platform or Website, and mobile and Internet usage.&nbsp; These third parties use cookies, pixel tags, and other
                            related technologies to help analyze and provide us the data. By visiting and using the Platform, you consent, to the extent permitted under applicable law, to the processing of data about you by these analytics and customer
                            support providers in the manner and for the purposes set out in this Privacy Policy.
                        </span>
                    </li>
                </ol>
             
                <li class="li1">
                    <span class="s1"><strong>How We Use and Share the Information</strong></span>
                </li>
                
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp; &nbsp;You authorize us to use the Personal Information, the Billing Information, the Geolocational Information, and the Other Information (collectively, the &ldquo;Information&rdquo;) to provide the Platform or
                        Website and to improve the same; to process transactions; to solicit your feedback; and to inform you about our products and services and those of our promotional partners.
                    </span>
                </p>
                <p class="p3"><span class="s2">&nbsp; &nbsp; &nbsp;You also authorize us to use and/or share Information as described below.</span></p>
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;We will access, use, and share the Information as required to fulfill our contractual obligations to you, provide you with support, and to address your questions or requests regarding our Platform, Website, or
                        services.
                    </span>
                </p>
                <p class="p5">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;In order to be responsive to you, to provide effective services to you, and to maintain our business relationship, as a matter of our legitimate interests, we will use your Personal Information to:
                    </span>
                </p>
                <ul class="ul1" style={{listStyleType: "disc"}}>
                    <li class="li6"><span class="s1">administer the Website and the Platform, and for internal operations, in order to conduct</span></li>
                    <li class="li6"><span class="s1">troubleshooting, data analysis, testing, research, statistical and survey analysis;</span></li>
                    <li class="li7"><span class="s1">maintain the safety and security of our users, the Platform or Website, and business;</span></li>
                    <li class="li7">
                        <span class="s1">send you announcements in relation to security, privacy or administrative related communications (these communications are not marketing orientated, and we do not rely on consent, so you may not opt out);</span>
                    </li>
                    <li class="li7"><span class="s1">generally improve and develop our products and services.</span></li>
                </ul>
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;We may employ other companies and individuals to perform functions on our behalf.&nbsp; Examples may include providing technical assistance, customer service, and marketing assistance.&nbsp; For example, TI uses
                        third-party cloud hosting providers to store user Information and configurations.&nbsp; These other companies will have access to the Information only as necessary to perform their functions and to the extent permitted by law.
                    </span>
                </p>
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;In an ongoing effort to better understand our customers and our Platform or Website, we may analyze certain Information in anonymized and/or aggregate form in order to operate, maintain, manage, and improve the
                        Platform or Website and services.&nbsp; This anonymous information does not identify you personally. We may use this anonymous/aggregate information, and share and/or license it with and to our affiliates, agents, business and
                        promotional partners, and other third parties. We may also disclose anonymous/aggregate user statistics in order to describe our Platform, Website, or services and business to current and prospective business partners and to other
                        third parties for other lawful purposes.
                    </span>
                </p>
                <p class="p3">
                    <span class="s2">&nbsp; &nbsp; &nbsp;We will not share any of your Personal Information with any of our parent companies, subsidiaries, joint ventures, or other companies under common control with us, in our legitimate interests.</span>
                </p>
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;To the extent permitted by law, we may also disclose the Information: (i) in response to lawful requests by public authorities, including for the purpose of meeting national security or law enforcement
                        requirements, (ii) when required by law, court order, or other government or law enforcement authority or regulatory agency; or (iii) whenever we believe that disclosing such Information is necessary or advisable, for example, to
                        protect the rights, property, or safety of TI or others to comply with law or in our legitimate business interests.
                    </span>
                </p>

                
                <li class="li1">
                    <span class="s1"><strong>Retention of Personal Information</strong></span>
                </li>
  
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;We will also retain and use your personal data to the extent necessary to comply with our legal obligations, resolve disputes and enforce our terms and conditions, other applicable terms of service, and our
                        policies. We will retain your Personal Information as follows:
                    </span>
                </p>
                <ul class="ul1">
                    <li class="li4"><span class="s1">your account data for as long as you continue to use the Platform, Website or services;</span></li>
                    <li class="li5"><span class="s1">if you contact us, we will keep your data for 24 months after you contact us; and</span></li>
                    <li class="li5"><span class="s1">your technical usage information for 24 months</span>&nbsp;</li>
                </ul>
                <p class="p7">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;If you stop using our Services, we will store your information (including your end users&rsquo; information) in an aggregated and anonymized format; we may use this information indefinitely without further notice
                        to you.
                    </span>
                </p>
                
                <li class="li1">
                    <span class="s1"><strong>How We Protect Your Personal Information</strong></span>
                </li>
          
                <p class="p8">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;We implement reasonable and appropriate security measures to protect your Personal Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction, taking into account the risks
                        involved in processing and the nature of such data, and comply with applicable laws and regulations. Please understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases, nor can we
                        guarantee that the Personal Information that you supply will not be intercepted while being transmitted to and from us over the Internet.
                    </span>
                </p>


                <li class="li1">
                    <span class="s1"><strong>Rights in relation to your Personal Information and Communication Preferences</strong></span>
                </li>
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;If you have registered for the Platform, you may access, review, make changes to, and delete your Personal Information by following the instructions found on the Platform or by sending an email to us
                        at&nbsp;support@thunderstake.io&nbsp;with the subject line, &ldquo;Privacy Policy.&rdquo;
                    </span>
                </p>
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;In addition, you may manage your receipt of marketing and non-transactional communications by clicking on the &ldquo;Unsubscribe&rdquo; link located on the bottom of any TI marketing email, or by sending us an
                        email at&nbsp;support@thunderstake.io&nbsp;with the subject line, &ldquo;Privacy Policy.&rdquo;&nbsp; Registered users cannot opt out of receiving transactional or administrative e-mails related to their Platform account.&nbsp; We
                        will use commercially reasonable efforts to process such requests in a timely manner in compliance with applicable laws. You should be aware, however, that it is not always possible to completely remove or modify information in our
                        databases.&nbsp;
                    </span>
                </p>
                <p class="p3"><span class="s2">&nbsp; &nbsp; &nbsp;Privacy Rights for EEA/UK Residents</span></p>
                <p class="p5"><span class="s2">&nbsp; &nbsp; &nbsp;If you are in the EEA or the UK, you have certain other rights in relation to your Personal Information:&nbsp;</span></p>
                <ul class="ul1" style={{listStyleType: "disc", marginBottom: "20px"}}>
                    <li class="li6">
                        <span class="s1">Portability: You have the right to receive a copy of the Personal Information we hold about you and to request that we transfer it to a third party, in certain circumstances and with certain exceptions.</span>
                    </li>
                    <li class="li7">
                        <span class="s1">Restriction of processing to storage only: You have the right to require us to stop processing the Personal Information we hold about you, other than for storage purposes, in certain circumstances.</span>
                    </li>
                    <li class="li7"><span class="s1">Objection: You have the right to object to our processing of your Personal Information</span></li>
                    <li class="li7"><span class="s1">Withdrawal of consent: Where we rely on consent to process your Personal Information, you have the right to withdraw this consent at any time.</span></li>
                </ul>
                <p class="p8"><span class="s2">To exercise these rights, please email us at&nbsp;support@thunderstake.io</span></p>
                <p class="p3"><span class="s2">&nbsp; &nbsp; &nbsp;Data Transfers&nbsp;</span></p>
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;The servers used in connection with the operation and use of the Website and Platform are operated in the United States and elsewhere. Please be aware that your Information, including, but not limited to, your
                        Personal Information and Billing Information, will be transferred to, processed, maintained, and used on computers, servers, and systems located outside of your state, province, country, or other governmental jurisdiction where the
                        privacy laws may not be as protective as those in your jurisdiction.&nbsp; If you are located outside the United States and choose to use the Platform or Website, you hereby consent to such transfer, processing, and use in the
                        United States and elsewhere.
                    </span>
                </p>

                <li class="li1">
                    <span class="s1"><strong>Children</strong></span>
                </li>
                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;We do not knowingly collect Personal Information from children under the age of 13 through the Platform.&nbsp; If you are under 13, please do not give us any Personal Information. We encourage parents and legal
                        guardians to monitor their children&rsquo;s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Information through the Platform or Website.&nbsp; If you have reason to
                        believe that a child under the age of 13 has provided Personal Information to us, please contact us, and we will endeavor to delete that information from our databases.
                    </span>
                </p>
          
                <li class="li1">
                    <span class="s1"><strong>California Residents</strong></span>
                </li>
                
                <p class="p3">
                    <span class="s2">
                        Terms used in this section and not otherwise defined have the meaning given to them under the California Consumer Privacy Act (<strong>&ldquo;CCPA&rdquo;</strong>). We do not sell personal information collected about you.&nbsp;
                    </span>
                </p>
                <p class="p3">
                    <span class="s2"><strong>Collection and Disclosure.&nbsp;</strong>In the preceding 12 months, we collected and disclosed personal information about you as described above in Sections 1 and 2 of this Privacy Policy.</span>
                </p>
                <p class="p3">
                    <span class="s2"><strong>Sources of Information</strong>.&nbsp;In the preceding 12 months, we received personal information from the sources described above in Section 1 of this Privacy Policy.</span>
                </p>
                <p class="p3"><span class="s2">In the preceding 12 months, we collected and disclosed for a business purpose the following categories of personal information about California consumers:</span></p>
                <p class="p5">
                    <span class="s3"><strong>Purposes for Collection, Use, and Sharing</strong>.</span>
                    <span class="s2">&nbsp;We use and disclose the personal information we collect for our commercial purposes, as further described in this Privacy Policy, including for our business purposes:</span>
                </p>
                <ul class="ul1" style={{listStyleType: "disc", marginBottom: "20px"}}>
                    <li class="li6"><span class="s1">Auditing related to our interactions with you;</span></li>
                    <li class="li7"><span class="s1">Legal compliance;</span></li>
                    <li class="li7"><span class="s1">Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and necessary prosecution;</span></li>
                    <li class="li7"><span class="s1">Debugging;</span></li>
                    <li class="li7"><span class="s1">Performing services (for us or our service provider);</span></li>
                    <li class="li7"><span class="s1">Internal research for technological improvement;</span></li>
                    <li class="li7"><span class="s1">Internal operations;</span></li>
                    <li class="li7"><span class="s1">Activities to maintain and improve our services; and</span></li>
                    <li class="li7"><span class="s1">Other one-time or short-term uses.</span></li>
                </ul>
                <p class="p5">
                    <span class="s3"><strong>Your Rights</strong>.</span>
                    <span class="s2">
                        &nbsp;Where applicable, if you are a California resident you may have the following rights under CCPA in relation to &ldquo;personal information&rdquo; we have collected about you as defined in the CCPA; these rights are subject to
                        verification and any applicable exceptions:
                    </span>
                </p>
                <ul class="ul1" style={{listStyleType: "disc", marginBottom: "20px"}}>
                    <li class="li6">
                        <span class="s1"><em>Right to Know/Access</em>: You have the right to request that we disclose certain information to you about our collection and use of certain personal information about you as described below:</span>
                        <ul class="ul1" style={{listStyleType: "disc", marginBottom: "20px"}}>
                            <li class="li7"><span class="s1">The specific pieces of personal information collected;</span></li>
                            <li class="li7"><span class="s1">The categories of personal information collected;</span></li>
                            <li class="li7"><span class="s1">The categories of sources from whom the personal information is collected</span></li>
                            <li class="li7"><span class="s1">The purpose for collecting the personal information; and</span></li>
                            <li class="li7"><span class="s1">The categories of third parties with whom we have shared the personal information.</span></li>
                        </ul>
                    </li>
                    <li class="li6">
                        <span class="s1"><em>Right to Delete</em>: You have the right to request that we delete the personal information</span>
                    </li>
                    <li class="li6">
                        <span class="s1"><em>Freedom from Discrimination</em>: You have the right to be free from unlawful discrimination for exercising any of the rights above.</span>
                    </li>
                </ul>
                <p class="p3">
                    <span class="s2">
                        To make a request in relation to the above rights, please contact us at&nbsp;<a href="mailto:support@thunderstake.io"><span class="s6">support@thunderstake.io</span></a>&nbsp; To fulfill your request, we will need to verify your
                        identity and residency in California, and ask for additional information and documents.&nbsp;
                    </span>
                </p>
                <p class="p3">
                    <span class="s2">
                        Only you, or someone legally authorized to act on your behalf, may make a request related to personal information collected about you. To designate an authorized agent, the authorized agent must provide sufficient information that
                        allows us to reasonably verify that they have been authorized by you to act on their behalf.
                    </span>
                </p>

                <li class="li1">
                    <span class="s1"><strong>Changes to This Privacy Policy</strong></span>
                </li>

                <p class="p3">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;This Privacy Policy is effective as of the date stated at the top of this Privacy Policy.&nbsp; We may change this Privacy Policy from time to time. Any such changes will be posted on the Platform or Website in
                        the form of an updated Privacy policy. By accessing the Platform or Website after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by
                        applicable law, our use of the Information is governed by the Privacy Policy in effect at the time we collect the Information.&nbsp; Please refer to this Privacy Policy on a regular basis.&nbsp; The previous Privacy Policy is
                        available&nbsp;<a href="https://blockdaemon.com/privacy-policy-old/" aria-invalid="true"><span class="s3">here</span></a>.&nbsp;
                    </span>
                </p>

                <li class="li1">
                    <span class="s1"><strong>How to Contact Us</strong></span>
                </li>

                <p class="p5">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;If you have questions about this Privacy Policy, please contact TI via e-mail at <a href="mailto:support@thunderstake.io"><span class="s4">support@thunderstake.io</span></a>
                    </span>
                </p>
                <p class="p5">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;If you have complaints about how we process your Personal Information, please contact us at&nbsp;<a href="mailto:support@thunderstake.io"><span class="s4">support@thunderstake.io</span></a> and we will respond to
                        your request as soon as possible.
                    </span>
                </p>
                <p class="p5">
                    <span class="s2">
                        &nbsp; &nbsp; &nbsp;If you are an EEA or UK resident and think we have infringed data protection laws, you can file a claim with the data protection supervisory authority in the EEA country in which you live or work or where you
                        think we have infringed data protection laws, or with the UK Information Commissioner&rsquo;s Office, as applicable to you.
                    </span>
                </p>



              </ol>
            </div>
          </div>

          
          {/* <div className="mt-[122px] mx-auto max-w-[900px]">
            <p className="font-medium leading-[54px] text-3xl lg:text-[36px] text-white text-center">
              {sanityAbout?.teamMembers?.title}
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-x-5 gap-y-16 mt-[44px]">
              {sanityAbout?.teamMembers?.teamList.map((item, index) => (
                <div
                  className="rounded-[12.54px] bg-[#2E2E2E]/[0.38] flex flex-col items-center"
                  key={index}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(null)}
                >
                  <div className="h-[180px] w-[180px] mt-5 mx-[15px] image-circle">
                    <GatsbyImage
                      image={
                        hover === index
                          ? item?.hoverPhoto?.asset?.gatsbyImageData
                          : item?.photo?.asset?.gatsbyImageData
                      }
                      alt="team-photo"
                      className="rounded-full"
                    />
                  </div>
                  <p className="font-medium leading-[30px] mt-[13.32px] text-[20px] text-white text-center">
                    {item.title}
                  </p>
                  <div className="mt-[9.68px] mb-[21px] flex items-center">
                    <a href={item.twitter} target="_blank">
                      <Twitter />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>  */}
        </div>
      </div>
      <div className="h-[40px]" />
    </Layout>
  );
}